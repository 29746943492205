import React from 'react'
import { Link } from 'gatsby'
import classNames from 'classnames'

import LogoIcon from './logo.inline.svg'

import './styles.css'

const Logo = ({ color, width, className }) => {
  return (
    <Link to="/" className="no-underline">
      <LogoIcon
        style={{ width }}
        className={classNames(
          {
            logo: true,
            'logo--white': color === 'white'
          },
          className
        )}
      />
    </Link>
  )
}

export default Logo
