import React, { Fragment } from 'react'
import { Link } from 'gatsby'
import classNames from 'classnames'

import navLinks from '@constants/nav'
import Logo from '@components/logo'

import EmailIcon from './icons/email.inline.svg'
import PhoneIcon from './icons/phone.inline.svg'
import './styles.css'

const Footer = () => {
  const date = new Date()
  return (
    <Fragment>
      <footer className="bg-navy white pt5 pt6-l">
        <div className="container">
          <Logo color="white" width="132px" className="db center" />

          <nav className="mv5 mv6-l mw5-sm center">
            <ul className="flex flex-wrap flex-column-sm list">
              {navLinks.map(item => (
                <li
                  key={item.text}
                  className="link-separator link-separator-none-sm flex-grow-1 bb-list-sm"
                >
                  <Link
                    to={item.link}
                    className={classNames({
                      'white no-underline underline-hover': true,
                      'db ph3 tc ttu fw3 f11 f10-l tracked-mega': true,
                      'pv3-sm': true
                    })}
                  >
                    {item.text}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>

          <div className="flex items-center justify-center flex-column-sm mb6">
            <div className="flex items-center mh5 justify-center justify-start-l mb3-sm">
              <PhoneIcon className="mr3 flex-shrink-0" />
              <a
                href="tel:0341089510"
                className="white f8 f7-l fw5 no-underline underline-hover"
              >
                03 41089510
              </a>
            </div>

            <div className="flex items-center justify-center mh5">
              <EmailIcon className="mr3 flex-shrink-0" />
              <a
                href="mailto:vuokrakodit@tuuliacapital.fi"
                className="white f10 f9-l no-underline underline-hover"
              >
                vuokrakodit@tuuliacapital.fi
              </a>
            </div>
          </div>

          <div className="tc f11 pv4 o-50 rights-separator">
            &copy;
            {date.getFullYear()} Tuulia Kodit. All Rights reserved.
            <Link className="white fw5 no-underline underline-hover ml6" to="/tietosuojaseloste">Tietosuojaseloste</Link>
          </div>
        </div>
      </footer>
    </Fragment>
  )
}

export default Footer
