import React from 'react'
import { Helmet } from 'react-helmet'
import { withPrefix } from 'gatsby'

const faviconLinks = [
  {
    rel: 'icon',
    type: 'image/png',
    sizes: '16x16',
    href: withPrefix('/favicon-16x16.png')
  },
  {
    rel: 'icon',
    type: 'image/png',
    sizes: '32x32',
    href: withPrefix('/favicon-32x32.png')
  },
  {
    rel: 'mask-icon',
    color: '#9aca3c',
    href: withPrefix('/safari-pinned-tab.svg')
  }
]

const Seo = () => <Helmet link={faviconLinks} />

export default Seo
