import React, { Fragment, useState } from 'react'
import classNames from 'classnames'

import NavLinks from '../nav-links'

import Menu from './icons/menu.inline.svg'
import Cross from './icons/cross.inline.svg'
import './styles.css'

const MobileNav = () => {
  const [navIsOpen, setNavState] = useState(false)

  const onNavOpen = () => {
    setNavState(true)
    document.body.style.overflow = 'hidden'
  }
  const onNavClose = () => {
    setNavState(false)
    document.body.style.overflow = 'visible'
  }

  return (
    <Fragment>
      <button
        className="header-nav-toggler pa1"
        onClick={onNavOpen}
        area-label="open navigation"
      >
        <Menu />
      </button>

      <div
        className={classNames({
          'header-nav-darkness': true,
          'header-nav-darkness--visible': navIsOpen
        })}
        onClick={onNavClose}
      />

      <nav
        className={classNames({
          'header-nav': true,
          'header-nav--open': navIsOpen
        })}
      >
        <button
          className="header-nav-toggler pa2 ma2 ml-auto"
          onClick={onNavClose}
          area-label="close navigation"
        >
          <Cross />
        </button>

        <NavLinks onClose={onNavClose} />
      </nav>
    </Fragment>
  )
}

export default MobileNav
