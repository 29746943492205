import React from 'react'

import Logo from '@components/logo'

import MobileNav from './mobile-nav'
import './styles.css'

const Header = () => {
  return (
    <header className="pv3-sm pv4">
      <div className="container flex items-center justify-between">
        <Logo className="header-logo" />
        <MobileNav />
      </div>
    </header>
  )
}

export default Header
