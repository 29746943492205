import React from 'react'
import { Link } from 'gatsby'
import classNames from 'classnames'

import navLinks from '@constants/nav'

const NavLinks = ({ onClose }) => {
  return (
    <ul
      className={classNames({
        'flex flex-wrap justify-end list': true,
        'flex-column-sm pa3-sm flex-grow-1-sm overflow-auto-sm': true
      })}
    >
      {navLinks.map(item => {
        const { text, link, isButton } = item
        return (
          <li
            key={link}
            className={classNames({
              relative: true,
              'link-separator': !isButton,
              'link-separator-none-sm bb-list-dark-sm': !isButton,
              'mt3-sm self-start-sm': isButton
            })}
            onClick={onClose}
          >
            <Link
              to={link}
              className={classNames({
                'db ttu fw6 ph5 pv3 transition-all': true,
                'no-underline tracked f11 f10-l': true,
                'hover-green dark-gray': !isButton,
                'green ba b--green br5 tc': isButton,
                'hover-bg-green hover-white': isButton
              })}
              activeClassName="green"
            >
              {text}
            </Link>
          </li>
        )
      })}
    </ul>
  )
}

export default NavLinks
