import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import Header from '@components/header'
import Footer from '@components/footer'

import '@assets/styles/style.css'

import Seo from './seo'

const Layout = ({ children, currentLocation }) => (
  <Fragment>
    <Seo />
    <div className="flex flex-column min-vh-100 avenir lh-copy dark-gray f10-sm f9">
      <Header currentLocation={currentLocation} />
      <main className="flex-grow-1">{children}</main>
      <Footer />
    </div>
  </Fragment>
)

Layout.propTypes = {
  children: PropTypes.any,
  currentLocation: PropTypes.string
}

export default Layout
