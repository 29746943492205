const navLinks = [
  {
    text: 'Koti',
    link: '/'
  },
  // {
  //   text: 'Alueet',
  //   link: '/alueet'
  // },
  {
    text: 'Asunnot',
    link: '/asunnot'
  },
  {
    text: 'Asukkaalle',
    link: '/asukkaalle'
  },
  {
    text: 'Vuokrahakemus',
    link: '/vuokrahakemus'
  },
  {
    text: 'Yhteystiedot',
    link: '/yhteystiedot',
    isButton: true
  }
]

export default navLinks
